import {animateScroll as scroll} from "react-scroll/modules";


export const appearanceAnimation = {
    hidden: {
        y: 0,
        opacity: 0,
    },
    visible: (custom: number) => ({
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: custom * 0.2
        },
    })
}

export const scrollToTop = () => {
    const options = {
        duration: 700,
        spy: true,
        smooth: true,
    };

    scroll.scrollToTop(options);
};

import style from './Main.module.scss';
import video from '../../assets/images/IMG_9899.mp4';
import gif from '../../assets/images/IMG_9899.gif';
import gifSlowed from '../../assets/images/IMG_9899Sasha.gif';
import {Link} from "react-scroll";
import {ReactComponent as ScrollDown} from "../../assets/images/scrollDown.svg";
import {ReactComponent as Swither} from "../../assets/images/Swither.svg";
import {useMemo} from "react";


function iOS() {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


export const Main = () => {

    const isIos = useMemo(iOS, [])

    return (
        <div className={style.Wrapper}>
            <div className={style.Container}>
                <div className={style.DescBlock}>
                    <h1 className={style.Title}>Turning Ideas into Reality<span>.</span></h1>
                    <p className={style.Desc}>Delivering high-quality IT solutions powered by advanced technologies and
                        innovative
                        approaches</p>
                    <div className={style.BtnsBlock}>
                        <button className={style.ContactBtn}>
                            <Link
                                to="ContactUs"
                                spy={true}
                                smooth={true}
                                duration={900}>
                                Contact Us
                            </Link>
                        </button>
                        <button className={style.ProjectBtn}>
                            <Link
                                to="Projects"
                                spy={true}
                                offset={-100}
                                smooth={true}
                                duration={900}>
                                Projects
                            </Link>
                        </button>
                    </div>
                </div>
                {/*<img src={gif} className={style.CircleGif} alt=""/>*/}
                {
                    isIos ? <img src={gifSlowed} className={style.CircleGif} alt=""/>
                        : <video autoPlay loop muted className={style.Circle} playsInline controls={false}>
                            <source src={video} type="video/mp4"/>
                        </video>
                }

                <div className={style.WrSvgs}>
                    <ScrollDown className={style.ScrollDown}/>
                    <Link
                        to="ContactUs"
                        spy={true}
                        smooth={true}
                        className={style.Switcher}
                        duration={900}>
                        <Swither/>
                    </Link>

                </div>

            </div>
        </div>
    )
}